import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Gallery1, Nft1, Nft2, Gallery2, Gallery3, Roadmap } from '../../assets';

function Aboutus() {
  return (
    <>
      <section className='whitepaper-1'>
        <Container>
          <Row>
            <Col sm={12}>
              <h3 className="whitepaper1-a">About Us</h3>
            </Col>
          </Row>
        </Container>
      </section>

      <section className='main3 about-page'>
        <Container>
          <Row>
            <Col sm={12}>
              <h3 className="main1-a">ABOUT US</h3>
              <h3 className="main3-a">Company Profile:</h3>
              <p className="main1-b"> Queen Artistry is a leading NFT-minting platform whose mission is to turn ordinary moments into classic art. We provide a distinctive collection of digital art with a butterfly theme, as a visual narrative of human development, that shows, each person's journey is a masterpiece in the process. Discover beauty and empowerment by accepting life's inevitable changes at Queen Artistry. </p>
              <h3 className="main3-a">Know Your Artist:</h3>
              <p className="main1-b"> A Baltimore native and retired firefighter, Larry M. Talley, Sr. has worn many hats: firefighter, co-owner of a tavern, trucking firm owner, and now, CEO of Spiritually Bound Publishing. Despite no formal training, faith-driven Larry embarked on a music career. His songs echo life experiences and inspiration. A man with multiple passions- music, travel, sports, and photography- Larry finds joy in family life with his wife, their son, and six grandchildren. He lives by the motto, Our purpose of life is to pursue our purpose in life.” </p>
            </Col>
            <Col sm={4} className='mt-3'>
              <img src={Gallery1} className='gallery1' />
            </Col>
            <Col sm={4} className='mt-3'>
              <img src={Gallery2} className='gallery1' />
            </Col>
            <Col sm={4} className='mt-3'>
              <img src={Gallery3} className='gallery1' />
            </Col>
            <Col sm={12} className='mt-5 mx-auto'>
              <Button href="https://larrytalleymusic.com/" className='learn-btn1 mx-auto'>Learn More About The Artist</Button>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Aboutus