import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import { Gallery1, Nft1, Nft2, Gallery2, Gallery3, Roadmap } from '../../assets';

function Home() {
    return (
        <>
            <section className="main1">
                <Container>
                    <Row className='align-items-center'>
                        <Col sm={6}>
                            <h3 className="main1-a">Art That Mimics Metamorphosis!</h3>
                            <p className="main1-b">At Queen Artistry, life is a beautiful journey that brings unique transformative stages. The Queen Artistry Collection welcomes you on a visual journey, reflecting the essence of your life stages through stunning NFT butterflies. Each beautifully produced image represents a stage of your transformation: a whisper from the past, a vibrant blossom from the present, and an optimistic flight into the future.<br /><br />Unlock My Queen Artistry NFT Now!</p>
                            <Button href="#" className='connect-btn'>Connect Wallet</Button>
                        </Col>
                        <Col sm={6}>
                            <img src={Nft1} className='nft1' />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="main2">
                <Container>
                    <Row className='align-items-center'>
                        <Col sm={6}>
                            <img src={Nft2} className='nft1' />
                        </Col>
                        <Col sm={6}>
                            <h3 className="main1-a">Queen Artistry Vision:</h3>
                            <p className="main1-b">At Queen Artistry, our vision is to remind you that change isn't something to fear but to enjoy. It is irresistible whether you welcome it or not. The Queen Artistry collection will inspire and accompany you in every stage of your life journey and destination. Manifesting timeless beauty found in these stages of transformation, we don't just offer gorgeous butterflies, our NFTs portray your present, past, and future aspirations and partnerships. <br /> <br /> Discover My Journey! </p>
                            <div className="btn-div">
                                <Link to="#" className='connect-btn1'>Create Your Account</Link>
                                <Link to="./Aboutus" className='connect-btn1'>About NFT</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="main3">
                <Container>
                    <Row>
                        <Col sm={8}>
                            <h3 className="main1-a">ABOUT US</h3>
                            <h3 className="main3-a">Company Profile:</h3>
                            <p className="main1-b"> Queen Artistry is a leading NFT-minting platform whose mission is to turn ordinary moments into classic art. We provide a distinctive collection of digital art with a butterfly theme, as a visual narrative of human development, that shows, each person's journey is a masterpiece in the process. Discover beauty and empowerment by accepting life's inevitable changes at Queen Artistry. </p>
                            <h3 className="main3-a">Know Your Artist:</h3>
                            <p className="main1-b"> A Baltimore native and retired firefighter, Larry M. Talley, Sr. has worn many hats: firefighter, co-owner of a tavern, trucking firm owner, and now, CEO of Spiritually Bound Publishing. Despite no formal training, faith-driven Larry embarked on a music career. His songs echo life experiences and inspiration. A man with multiple passions- music, travel, sports, and photography- Larry finds joy in family life with his wife, their son, and six grandchildren. He lives by the motto, Our purpose of life is to pursue our purpose in life.” </p>
                        </Col>
                        <Col sm={4}></Col>
                        <Col sm={4} className='mt-3'>
                            <img src={Gallery1} className='gallery1' />
                        </Col>
                        <Col sm={4} className='mt-3'>
                            <img src={Gallery2} className='gallery1' />
                        </Col>
                        <Col sm={4} className='mt-3'>
                            <img src={Gallery3} className='gallery1' />
                        </Col>
                        <Col sm={12} className='mt-5 mx-auto'>
                            <Button href="https://larrytalleymusic.com/" className='learn-btn1 mx-auto'>Learn More About The Artist</Button>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='main4'>
                <Container>
                    <Row>
                        <Col md={12}>
                            <h3 className="main1-a">ROAD MAP</h3>
                            <img src={Roadmap} className='roadmap mt-4' />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Home